var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.showHerder)?_c('div',{staticClass:"header"},[_c('headerVue',{attrs:{"user":_vm.user}})],1):_vm._e(),_c('div',{staticClass:"bigBox"},[(_vm.showmenuVue)?_c('div',{staticClass:"menu"},[_c('menuVue',{attrs:{"user":_vm.user,"path":_vm.path}})],1):_vm._e(),_c('div',{staticClass:"main",style:(_vm.showHerder ? 'min-height: calc(100vh - 50px);' : 'min-height: 100vh')},[_c('div',{staticClass:"watchBox",style:(!_vm.showHerder ? '' : 'border-radius: 4px;')},[_c('router-view',{ref:"mainBox",on:{"openDialog":_vm.openDialog}})],1)])]),(_vm.showFooter)?_c('div',{staticClass:"footer"},[_vm._v("\n    ©2023 All Rights Reserved 京ICP备2023005450号 北京研斯数据科技有限公司\n  ")]):_vm._e(),_c('a-modal',{attrs:{"title":_vm.dialogMsg.isSureOrNo === 1 ? '确认接评' : '拒绝接评',"visible":_vm.isOpenDialog,"keyboard":false,"cancel-button-props":{ style: { display: 'none' } },"okText":"确认","width":500,"cancelText":"关闭","footer":null},on:{"ok":_vm.sure,"cancel":function($event){_vm.isOpenDialog = false}}},[_c('div',{staticClass:"dialogMain nowidth"},[_c('div',{staticClass:"lunwenTitle"},[_vm._v("\n        "+_vm._s(_vm.dialogMsg.isSureOrNo === 1 ? "确认" : "拒绝")+"接收论文《"+_vm._s(_vm.dialogMsg.thesisName)+"》的评审？\n      ")]),_c('div',{staticClass:"main"},[(_vm.$store.state.userMsg.institutionType == 1)?_c('div',{staticClass:"item",staticStyle:{"margin-bottom":"10px"}},[_vm._v("\n          "+_vm._s(_vm.dialogMsg.companyExpertDesc)+"\n        ")]):_vm._e(),(
            _vm.$store.state.userMsg.institutionType == 0 &&
            _vm.dialogMsg.zhuanjiazhicheng &&
            _vm.dialogMsg.zhuanjialeix.length > 0
          )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"label"},[_vm._v("专家类型至少满足其一:")]),_c('div',{staticClass:"value"},_vm._l((_vm.dialogMsg.zhuanjialeix),function(item){return _c('a-tag',{key:item,attrs:{"color":"#3d81c9"}},[_vm._v(_vm._s(item))])}),1)]):_vm._e(),(
            _vm.$store.state.userMsg.institutionType == 0 &&
            _vm.dialogMsg.zhuanjiazhicheng &&
            _vm.dialogMsg.zhuanjiazhicheng.length > 0
          )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"label",staticStyle:{"text-align":"left"}},[_vm._v("\n            专家职称至少满足其一:\n          ")]),_c('div',{staticClass:"value"},_vm._l((_vm.dialogMsg.zhuanjiazhicheng),function(item){return _c('a-tag',{key:item,attrs:{"color":"#3d81c9"}},[_vm._v(_vm._s(item))])}),1)]):_vm._e(),(
            _vm.$store.state.userMsg.institutionType == 0 &&
            _vm.dialogMsg.professionalTitleLevels &&
            _vm.dialogMsg.professionalTitleLevels.length > 0
          )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"label",staticStyle:{"text-align":"left"}},[_vm._v("\n            专家职称级别至少满足其一:\n          ")]),_c('div',{staticClass:"value"},_vm._l((_vm.dialogMsg.professionalTitleLevels),function(item,i){return _c('a-tag',{key:i,attrs:{"color":"#3d81c9"}},[_vm._v(_vm._s(item))])}),1)]):_vm._e()]),(_vm.dialogMsg.isSureOrNo !== 1)?_c('div',[_c('div',{staticClass:"lunwenTitle",staticStyle:{"margin":"10px 0"}},[_vm._v("\n          请填写拒评的理由，方便我们更合理的分配论文。\n        ")]),_c('a-textarea',{attrs:{"placeholder":"请输入拒评的理由","auto-size":{ minRows: 3, maxRows: 6 }},model:{value:(_vm.rejectionReason),callback:function ($$v) {_vm.rejectionReason=$$v},expression:"rejectionReason"}})],1):_vm._e()]),_c('div',{staticClass:"modalFooter"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.sure}},[_vm._v("确认")])],1)]),_c('a-modal',{attrs:{"title":'系统提示',"visible":_vm.siscrepancyDialog,"keyboard":false,"cancel-button-props":{ style: { display: 'none' } },"okText":"确认","width":500,"cancelText":"关闭","footer":null},on:{"cancel":function($event){_vm.isOpenDialog = false}}},[_c('div',{staticClass:"dialogMain nowidth"},[_c('div',{staticClass:"lunwenTitle"},[_vm._v(_vm._s(_vm.siscrepancyMsg))])]),_c('div',{staticClass:"modalFooter"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onToInfo}},[_vm._v("去核对")]),_c('a-button',{staticStyle:{"margin-left":"20px"},on:{"click":_vm.onRefuse}},[_vm._v("拒绝接评")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }